import api from '../util/request.js'

export const createCobro = async ({ datos }) => {
  try {
    const { data } = await api.post('pagos/pago/crear', datos)
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getFacturas = async ({ cliente }) => {
  try {
    const { data } = await api.get(`pagos/${cliente}/facturas`)
    return data.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const infoCobro = async () => {
  try {
    const { data } = await api.get('pagos/infocobros')
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getCobros = async ({ inicio, final, status, id, type }) => {

  const URL_API = {
    vendedor: `vendedores/${id}/cobros`,
    cliente: `clientes/${id}/cobros`,
  }

  try {
    const { data } = await api.get(URL_API[type] ?? '',
      {
        params: {
          fecha_inicio: inicio,
          fecha_final: final,
          status: status,
        },
      },
    )
    return data.data
  } catch (error) {
    return Promise.reject(error)
  }
}
